<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col>
                    <v-alert :dismissible="false" class="white--text" color="#536DFE" dark border="left">
						<v-row no-gutters class="align-center">
                            <v-col cols="10" class="pa-0">
                            	<icon class="ml-4 mr-3"></icon>
                                <span>Crypto Price Market Cap</span>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
				<v-tabs v-model="tab" color="#536DFE">
					<v-tab v-for="item in symbolItems" :key="item">{{item}}</v-tab>
				</v-tabs>
            </v-row>
			<v-row dense v-if="loading">
			    <v-col cols="12">
			        <v-skeleton-loader dense type="table-row-divider@20" class="mx-auto"></v-skeleton-loader>
			    </v-col>
			</v-row>
			<v-row v-if="!loading">
				<v-col colspan="12">
					<v-card flat>
						<v-card-text>
							<v-data-table :headers="Headers" hide-default-footer disable-sort dense width="100%">
							    <template v-slot:body>
							        <tbody>
							            <tr v-for="(item,index) in baseDateList" :key="index">
											<td>{{ item.date }}</td>
											<td class="text-right">{{ common.formatNum(item.open.toFixed(4)) }}</td>
											<td class="text-right">{{ common.formatNum(item.high.toFixed(4)) }}</td>
											<td class="text-right">{{ common.formatNum(item.low.toFixed(4)) }}</td>
											<td class="text-right">{{ common.formatNum(item.close.toFixed(4)) }}</td>
											<td class="text-right">{{ common.formatNum(item.volume.toFixed(4)) }}</td>
											<td class="text-right">{{ common.formatNum(item.marketCap.toFixed(4)) }}</td>
							            </tr>
							        </tbody>
							    </template>
							</v-data-table>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '@/config';
	import { mapGetters } from "vuex";
	import icon from "@/components/icon"
    export default {
        data() {
            return {
				tab:null,
                symbolItems:[],
				baseDateList:[],
				loading:true,
				Headers:[
					{text:"Date"},
					{text:"Open",align:"right"},
					{text:"High",align:"right"},
					{text:"Low",align:"right"},
					{text:"Close",align:"right"},
					{text:"Volume",align:"right"},
					{text:"Market Cap",align:"right"},
				],
            }
        },
        computed: {
            ...mapGetters(['user']),
		},
		components:{
			icon
		},
        watch: {
            tab(val){
				this.getCmc(this.symbolItems[val]);
			}
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
            this.getSymbols();
        },
        created() {
        
        },
        methods: {
           getSymbols(){
			   this.loading = true;
               let api = config.baseUrl + "/admin/private/getCmcSymbols";
               this.$http.get(api).then(res=>{
                   let data = res.data;
                   if(data.code == 200){
                    this.symbolItems = data.result;
                   }else{
					   this.loading = false;
					   this.$store.dispatch('snackbarMessageHandler', data.message);
				   }
               },err=>{
				   
				   this.$store.dispatch('snackbarMessageHandler', "err");
			   });
           },
		   getCmc(symbol){
			this.loading = true;
			this.baseDateList = [];
			let api = config.baseUrl + "/admin/private/getCmc?symbol=" + symbol;
			this.$http.get(api).then(res=>{
			    let data = res.data;
			    if(data.code == 200){
					this.baseDateList = data.result;
					this.loading = false;
			    }else{
				   this.$store.dispatch('snackbarMessageHandler', data.message);
				   this.loading = false;
			   }
			},err=>{
			   this.$store.dispatch('snackbarMessageHandler', "err");
			   this.loading = false;
			})
		   },
        }
    }
</script>

<style>
svg{
}
</style>